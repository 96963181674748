<template>
  <div>
    <search-header></search-header>
    <div class="container p-0 pt-3 pb-3">
      <div class="row m-0">
        <div class="col-12 bg-white rounded p-0">
          <div class="card">
            <div class="card-header">土地基本信息</div>
            <div class="card-body">
                <div class="clearfix">
                    <div class="float-left text-right mr-3 mt-2 width-100">
                        <span class="text-danger mr-1">*</span><span>所在地区</span>
                    </div>
                    <div class="float-left areabox box">
                        <input @click.stop="isAreaShow = !isAreaShow" :value="areaValue"  class="form-control" type="text" placeholder="城市选择" readonly="readonly">
                        <em>
                            <b-icon icon="chevron-down"></b-icon>
                        </em>
                        <city v-model="isAreaShow" @onChoose="onChooseArea"></city>
                    </div>
                </div>
                <div class="clearfix mt-2">
                    <div class="float-left text-right mt-1 mr-3 width-100">
                        <span class="text-danger mr-1">*</span><span>土地用途</span>
                    </div>
                    <div class="float-left">
                        <div class="col" style="width:980px">
                            <div class="row screen">
                                <a href="javascript:;" @click="onClassClick(m)" :class="{ 'active' : classId == m.id }" v-for="m,i in dict.landUseType" :key="i" class="mr-3 px-2 py-1">{{m.text}}</a>
                            </div>
                            <div class="row screen mt-2">
                                <a href="javascript:;" @click="onUlClick(m)" :class="{ 'active' : landUse == m.id }" v-for="m,i in getChildUseLandList" :key="i" class="mr-3 px-2 py-1">{{m.text}}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="clearfix mt-1">
                    <div class="float-left text-right mt-2 mr-3 width-100">
                        <span class="text-danger mr-1">*</span><span>土地面积</span>
                    </div>
                    <div class="float-left">
                        <div class="clearfix" style="height: 38px; line-height: 38px;">
                            <input v-model="para.measureArea" @change="maChange" class="float-left form-control width-160" maxlength="10" type="number">
                                <span class="m-2">{{acreage}} {{ acreage == '亩' ? '（1亩≈666.7平米）' : '（1平方米≈0.0015亩）'}}</span>
                        </div>
                    </div>
                </div>
                <div class="clearfix mt-3">
                    <div class="float-left text-right mt-2 mr-3 width-100">
                        <span class="text-danger mr-1">*</span><span>流转年限</span>
                    </div>
                    <div class="float-left">
                        <div class="clearfix" style="height: 38px; line-height: 38px;">
                            <input v-model="para.years" class="float-left form-control width-160" maxlength="10" type="number">
                            <span class="m-2">年</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-header border-top">权证</div>
            <div class="card-body">
                 <div class="clearfix  mt-3">
                    <div class="float-left text-right mr-3 width-100">
                      <span>权证类型</span>
                    </div>
                    <div class="float-left">
                      <b-form-group>
                        <b-form-checkbox-group
                          :options="[ 
                          { text: '农村土地承包经营权', value: '农村土地承包经营权' },
                          { text: '农村集体土地使用证', value: '农村集体土地使用证' },
                          { text: '林权证', value: '林权证' }]"
                        ></b-form-checkbox-group>
                      </b-form-group>
                    </div>
                </div>
                <div class="clearfix">
                    <div class="float-left text-right mt-2 mr-3 width-100">
                      <span>权证有效期</span>
                    </div>
                    <div class="float-left">
                      <date-picker :editable="false" type="year" input-class="form-control width-180" style="width: 180px;"></date-picker>
                      <span class="mx-2">-</span> 
                      <date-picker :editable="false" type="year" input-class="form-control width-180" style="width: 180px;"></date-picker>
                    </div>
                </div>
                <div class="clearfix  mt-3">
                    <div class="float-left text-right mr-3 width-100">
                      <span>权属类型</span>
                    </div>
                    <div class="float-left">
                      <b-form-group>
                        <b-form-radio-group :options="[
                          { text: '个人', value: '个人' },
                          { text: '企业', value: '企业' },
                          { text: '国有', value: '国有' },
                          { text: '集体', value: '集体' }]"></b-form-radio-group>
                      </b-form-group>
                    </div>
                </div>
            </div>
            <div class="card-header border-top">地形地貌</div>
            <div class="card-body">
              <div class="clearfix">
                  <div class="float-left text-right mr-3 width-100">
                    <span>地形坡度</span>
                  </div>
                  <div class="float-left">
                    <b-form-group>
                      <b-form-radio-group :options="[
                        { text: '平坡', value: '平坡' },
                        { text: '缓坡', value: '缓坡' },
                        { text: '斜坡', value: '斜坡' },
                        { text: '陡坡', value: '陡坡' },
                        { text: '急陡坡', value: '急陡坡' },
                        { text: '急坡', value: '急坡' },
                        { text: '险坡', value: '险坡' }]"></b-form-radio-group>
                    </b-form-group>
                  </div>
              </div>
              <div class="clearfix">
                  <div class="float-left text-right mr-3 width-100">
                    <span>海拔高度</span>
                  </div>
                  <div class="float-left">
                    <b-form-group>
                      <b-form-radio-group :options="[
                        { text: '500米以下', value: '500米以下' },
                        { text: '500~1000米', value: '500~1000米' },
                        { text: '1000~2000米', value: '1000~2000米' },
                        { text: '2000米以上', value: '2000米以上' }]"></b-form-radio-group>
                    </b-form-group>
                  </div>
              </div>
              <div class="clearfix">
                  <div class="float-left text-right mr-3 width-100">
                    <span>方位</span>
                  </div>
                  <div class="float-left">
                    <b-form-group>
                      <b-form-radio-group :options="[
                        { text: '东南', value: '东南' },
                        { text: '东北', value: '东北' },
                        { text: '西南', value: '西南' },
                        { text: '西北', value: '西北' },
                        { text: '东', value: '东' },
                        { text: '南', value: '南' },
                        { text: '西', value: '西' },
                        { text: '北', value: '北' }]"></b-form-radio-group>
                    </b-form-group>
                  </div>
              </div>
              <div class="clearfix">
                  <div class="float-left text-right mr-3 width-100">
                    <span>地貌</span>
                  </div>
                  <div class="float-left">
                    <b-form-group>
                      <b-form-radio-group :options="[
                        { text: '规则', value: '规则' },
                        { text: '一般', value: '一般' },
                        { text: '不规则', value: '不规则' }]"></b-form-radio-group>
                    </b-form-group>
                  </div>
              </div>
              <div class="clearfix">
                  <div class="float-left text-right mr-3 width-100">
                    <span>地块形状</span>
                  </div>
                  <div class="float-left">
                    <b-form-group>
                      <b-form-radio-group :options="[
                        { text: '平坡', value: '平坡' },
                        { text: '缓坡', value: '缓坡' },
                        { text: '斜坡', value: '斜坡' },
                        { text: '陡坡', value: '陡坡' },
                        { text: '急陡坡', value: '急陡坡' },
                        { text: '急坡', value: '急坡' },
                        { text: '险坡', value: '险坡' }]"></b-form-radio-group>
                    </b-form-group>
                  </div>
              </div>
              <div class="clearfix">
                  <div class="float-left text-right mr-3 width-100">
                    <span>机械化工作</span>
                  </div>
                  <div class="float-left">
                    <b-form-group>
                      <b-form-radio-group :options="[
                        { text: '可以', value: '可以' },
                        { text: '不可以', value: '不可以' }]"></b-form-radio-group>
                    </b-form-group>
                  </div>
              </div>
              <div class="clearfix">
                  <div class="float-left text-right mr-3 width-100">
                    <span>平整程度</span>
                  </div>
                  <div class="float-left">
                    <b-form-group>
                      <b-form-radio-group :options="[
                        { text: '非常平整', value: '非常平整' },
                        { text: '平整', value: '平整' },
                        { text: '一般', value: '一般' },
                        { text: '不平整', value: '不平整' },
                        { text: '非常不平整', value: '非常不平整' }]"></b-form-radio-group>
                    </b-form-group>
                  </div>
              </div>
              <div class="clearfix">
                  <div class="float-left text-right mr-3 width-100">
                    <span>排水条件</span>
                  </div>
                  <div class="float-left">
                   <b-form-group>
                      <b-form-radio-group :options="[
                        { text: '充分满足', value: '充分满足' },
                        { text: '基本满足', value: '基本满足' },
                        { text: '一般满足', value: '一般满足' },
                        { text: '无排水体系', value: '无排水体系' }]"></b-form-radio-group>
                    </b-form-group>
                  </div>
              </div>
              <div class="clearfix">
                  <div class="float-left text-right mr-3 width-100">
                    <span>灌溉条件</span>
                  </div>
                  <div class="float-left">
                    <b-form-group>
                      <b-form-radio-group :options="[
                        { text: '充分满足', value: '充分满足' },
                        { text: '基本满足', value: '基本满足' },
                        { text: '一般满足', value: '一般满足' },
                        { text: '无灌溉条件', value: '无灌溉条件' }]"></b-form-radio-group>
                    </b-form-group>
                  </div>
              </div>
            </div>
            <div class="card-header border-top">土壤土质</div>
            <div class="card-body">
              <div class="clearfix">
                  <div class="float-left text-right mr-3 width-100">
                    <span>土壤质地</span>
                  </div>
                  <div class="float-left">
                    <b-form-group>
                      <b-form-radio-group :options="[
                        { text: '壤土', value: '壤土' },
                        { text: '粘土', value: '粘土' },
                        { text: '砂土', value: '砂土' },
                        { text: '砂砾土', value: '砂砾土' }]"></b-form-radio-group>
                    </b-form-group>
                  </div>
              </div>
              <div class="clearfix">
                  <div class="float-left text-right mr-3 width-100">
                    <span>土壤肥力</span>
                  </div>
                  <div class="float-left">
                    <b-form-group>
                      <b-form-radio-group :options="[
                        { text: '极高', value: '极高' },
                        { text: '高', value: '高' },
                        { text: '中上', value: '中上' },
                        { text: '中', value: '中' },
                        { text: '低', value: '低' },
                        { text: '极低', value: '极低' }]"></b-form-radio-group>
                    </b-form-group>
                  </div>
              </div>
              <div class="clearfix">
                  <div class="float-left text-right mr-3 width-100">
                    <span>土壤碱解氮</span>
                  </div>
                  <div class="float-left">
                    <b-form-group>
                      <b-form-radio-group :options="[
                        { text: '极高', value: '极高' },
                        { text: '高', value: '高' },
                        { text: '中上', value: '中上' },
                        { text: '中', value: '中' },
                        { text: '低', value: '低' },
                        { text: '极低', value: '极低' }]"></b-form-radio-group>
                    </b-form-group>
                  </div>
              </div>
              <div class="clearfix">
                  <div class="float-left text-right mr-3 width-100">
                    <span>土壤速效磷</span>
                  </div>
                  <div class="float-left">
                    <b-form-group>
                        <b-form-radio-group :options="[
                          { text: '极高', value: '极高' },
                          { text: '高', value: '高' },
                          { text: '中上', value: '中上' },
                          { text: '中', value: '中' },
                          { text: '低', value: '低' },
                          { text: '极低', value: '极低' }]"></b-form-radio-group>
                      </b-form-group>
                  </div>
              </div>
              <div class="clearfix">
                  <div class="float-left text-right mr-3 width-100">
                    <span>土壤速效钾</span>
                  </div>
                  <div class="float-left">
                    <b-form-group>
                      <b-form-radio-group :options="[
                        { text: '极高', value: '极高' },
                        { text: '高', value: '高' },
                        { text: '中上', value: '中上' },
                        { text: '中', value: '中' },
                        { text: '低', value: '低' },
                        { text: '极低', value: '极低' }]"></b-form-radio-group>
                    </b-form-group>
                  </div>
              </div>
              <div class="clearfix">
                  <div class="float-left text-right mr-3 width-100">
                    <span>土壤有机质</span>
                  </div>
                  <div class="float-left">
                    <b-form-group>
                      <b-form-radio-group :options="[
                        { text: '极高', value: '极高' },
                        { text: '高', value: '高' },
                        { text: '中上', value: '中上' },
                        { text: '中', value: '中' },
                        { text: '低', value: '低' },
                        { text: '极低', value: '极低' }]"></b-form-radio-group>
                    </b-form-group>
                  </div>
              </div>
              <div class="clearfix">
                  <div class="float-left text-right mr-3 width-100">
                    <span>土壤酸碱度</span>
                  </div>
                  <div class="float-left">
                    <b-form-group>
                      <b-form-radio-group :options="[
                        { text: '极强酸性', value: '极强酸性' },
                        { text: '强酸性', value: '强酸性' },
                        { text: '酸性', value: '酸性' },
                        { text: '弱酸性', value: '弱酸性' },
                        { text: '中性', value: '中性' },
                        { text: '弱碱性', value: '弱碱性' },
                        { text: '碱性', value: '碱性' },
                        { text: '强碱性', value: '强碱性' },
                        { text: '极强碱', value: '极强碱' },]"></b-form-radio-group>
                    </b-form-group>
                  </div>
              </div>
              
            </div>
            <div class="card-header border-top">气候降雨</div>
            <div class="card-body">
              <div class="clearfix">
                <div class="float-left text-right mr-3 width-100">
                  <span>气候类型</span>
                </div>
                <div class="float-left">
                  <b-form-group>
                    <b-form-radio-group :options="[
                      { text: '热带季风气候', value: '热带季风气候' },
                      { text: '亚热带季风气候', value: '亚热带季风气候' },
                      { text: '温带季风气候', value: '温带季风气候' },
                      { text: '温带大陆性气候', value: '温带大陆性气候' },
                      { text: '高原山地气候', value: '高原山地气候' }]"></b-form-radio-group>
                  </b-form-group>
                </div>
              </div>
              <div class="clearfix">
                <div class="float-left text-right mr-3 width-100">
                  <span>日照时长h/年</span>
                </div>
                <div class="float-left">
                  <b-form-group>
                    <b-form-radio-group :options="[
                      { text: '2200~3000', value: '2200~3000' },
                      { text: '3000~3200', value: '3000~3200' },
                      { text: '3200~3300', value: '3200~3300' },
                      { text: '>3300', value: '>3300' }]"></b-form-radio-group>
                  </b-form-group>
                </div>
              </div>
              <div class="clearfix">
                <div class="float-left text-right mr-3 width-100">
                  <span>日照率</span>
                </div>
                <div class="float-left">
                  <b-form-group>
                    <b-form-radio-group :options="[
                      { text: '充分', value: '充分' },
                      { text: '一般', value: '一般' },
                      { text: '不充分', value: '不充分' }]"></b-form-radio-group>
                  </b-form-group>
                </div>
              </div>
              <div class="clearfix">
                <div class="float-left text-right mr-3 width-100">
                  <span>年平均气温</span>
                </div>
                <div class="float-left">
                  <b-form-group>
                    <b-form-radio-group :options="[
                      { text: '0~10℃', value: '0~10℃' },
                      { text: '10~20℃', value: '10~20℃' },
                      { text: '20~30℃', value: '20~30℃' },
                      { text: '30℃以上', value: '30℃以上' },]"></b-form-radio-group>
                  </b-form-group>
                </div>
              </div>
              <div class="clearfix">
                <div class="float-left text-right mr-3 width-100">
                  <span>无霜期/天</span>
                </div>
                <div class="float-left">
                  <b-form-group>
                    <b-form-radio-group :options="[
                      { text: '0~100', value: '0~100' },
                      { text: '100~150', value: '100~150' },
                      { text: '150~200', value: '150~200' },
                      { text: '200~250', value: '200~250' },
                      { text: '250~300', value: '250~300' },
                      { text: '>300', value: '>300' }]"></b-form-radio-group>
                  </b-form-group>
                </div>
              </div>

              <div class="clearfix">
                <div class="float-left text-right mr-3 width-100">
                  <span>降雨量</span>
                </div>
                <div class="float-left">
                  <b-form-group>
                    <b-form-radio-group :options="[
                      { text: '0~50', value: '0~50' },
                      { text: '50~200', value: '50~200' },
                      { text: '200~400', value: '200~400' },
                      { text: '400~800', value: '400~800' },
                      { text: '800~1600', value: '800~1600' },
                      { text: '1600~3000', value: '1600~3000' },
                      { text: '>3000', value: '>3000' }]"></b-form-radio-group>
                  </b-form-group>
                </div>
              </div>

               <div class="clearfix">
                <div class="float-left text-right mr-3 width-100">
                  <span>湿度</span>
                </div>
                <div class="float-left">
                  <b-form-group>
                    <b-form-radio-group :options="[
                      { text: '非常干燥', value: '非常干燥' },
                      { text: '稍微干燥', value: '稍微干燥' },
                      { text: '最适当', value: '最适当' },
                      { text: '稍微潮湿', value: '稍微潮湿' },
                      { text: '非常潮湿', value: '非常潮湿' },]"></b-form-radio-group>
                  </b-form-group>
                </div>
              </div>
              
            </div>

            <div class="card-header border-top">交通情况</div>
            <div class="card-body">
              <div class="clearfix">
                <div class="float-left text-right mr-3 width-100">
                  <span>道路情况</span>
                </div>
                <div class="float-left">
                  <b-form-group>
                    <b-form-radio-group :options="[
                      { text: '土路', value: '土路' },
                      { text: '水路', value: '水路' },
                      { text: '水泥路', value: '水泥路' },
                      { text: '机耕路', value: '机耕路' }]"></b-form-radio-group>
                  </b-form-group>
                </div>
              </div>
              <div class="clearfix">
                <div class="float-left text-right mr-3 width-100">
                  <span>机场</span>
                </div>
                <div class="float-left">
                  <b-form-group>
                    <b-form-radio-group :options="[
                      { text: '充分满足', value: '充分满足' },
                      { text: '基本满足', value: '基本满足' },
                      { text: '一般满足', value: '一般满足' },
                      { text: '无灌溉条件', value: '无灌溉条件' }]"></b-form-radio-group>
                  </b-form-group>
                </div>
              </div>
              <div class="clearfix">
                <div class="float-left text-right mr-3 width-100">
                  <span>高速公路</span>
                </div>
                <div class="float-left">
                  <b-form-group>
                    <b-form-radio-group :options="[
                      { text: '充分满足', value: '充分满足' },
                      { text: '基本满足', value: '基本满足' },
                      { text: '一般满足', value: '一般满足' },
                      { text: '无灌溉条件', value: '无灌溉条件' }]"></b-form-radio-group>
                  </b-form-group>
                </div>
              </div>
              <div class="clearfix">
                <div class="float-left text-right mr-3 width-100">
                  <span>铁路</span>
                </div>
                <div class="float-left">
                  <b-form-group>
                    <b-form-radio-group :options="[
                      { text: '<15分钟', value: '<15分钟' },
                      { text: '<30分钟', value: '<30分钟' },
                      { text: '30-60分钟', value: '30-60分钟' },
                      { text: '>60分钟', value: '>60分钟' }]">
                    </b-form-radio-group>
                  </b-form-group>
                </div>
              </div>
              <div class="clearfix">
                <div class="float-left text-right mr-3 width-100">
                  <span>港口</span>
                </div>
                <div class="float-left">
                  <b-form-group>
                    <b-form-radio-group :options="[
                      { text: '<15分钟', value: '<15分钟' },
                      { text: '<30分钟', value: '<30分钟' },
                      { text: '30-60分钟', value: '30-60分钟' },
                      { text: '>60分钟', value: '>60分钟' }]">
                    </b-form-radio-group>
                  </b-form-group>
                </div>
              </div>
              <div class="clearfix">
                <div class="float-left text-right mr-3 width-100">
                  <span>高铁</span>
                </div>
                <div class="float-left">
                  <b-form-group>
                    <b-form-radio-group :options="[
                      { text: '<15分钟', value: '<15分钟' },
                      { text: '<30分钟', value: '<30分钟' },
                      { text: '30-60分钟', value: '30-60分钟' },
                      { text: '>60分钟', value: '>60分钟' }]">
                    </b-form-radio-group>
                  </b-form-group>
                </div>
              </div>
              <div class="clearfix">
                <div class="float-left text-right mr-3 width-100">
                  <span>省级公路</span>
                </div>
                <div class="float-left">
                  <b-form-group>
                    <b-form-radio-group :options="[
                      { text: '<15分钟', value: '<15分钟' },
                      { text: '<30分钟', value: '<30分钟' },
                      { text: '30-60分钟', value: '30-60分钟'},
                      { text: '>60分钟', value: '>60分钟' }]">
                    </b-form-radio-group>
                  </b-form-group>
                </div>
              </div>
              <div class="clearfix">
                <div class="float-left text-right mr-3 width-100">
                  <span>县级公路</span>
                </div>
                <div class="float-left">
                  <b-form-group>
                    <b-form-radio-group :options="[
                      { text: '<15分钟', value: '<15分钟' },
                      { text: '<30分钟', value: '<30分钟' },
                      { text: '30-60分钟', value: '30-60分钟' },
                      { text: '>60分钟', value: '>60分钟' }]">
                    </b-form-radio-group>
                  </b-form-group>
                </div>
              </div>
              <div class="clearfix">
                <div class="float-left text-right mr-3 width-100">
                  <span>乡镇公路</span>
                </div>
                <div class="float-left">
                  <b-form-group>
                    <b-form-radio-group :options="[
                      { text: '<15分钟', value: '<15分钟' },
                      { text: '<30分钟', value: '<30分钟' },
                      { text: '30-60分钟', value: '30-60分钟' },
                      { text: '>60分钟', value: '>60分钟' }]">
                    </b-form-radio-group>
                  </b-form-group>
                </div>
              </div>
              <div class="clearfix">
                <div class="float-left text-right mr-3 width-100">
                  <span>村道/自建路</span>
                </div>
                <div class="float-left">
                  <b-form-group>
                    <b-form-radio-group :options="[
                      { text: '<15分钟', value: '<15分钟' },
                      { text: '<30分钟', value: '<30分钟' },
                      { text: '30-60分钟', value: '30-60分钟' },
                      { text: '>60分钟', value: '>60分钟' }]">
                    </b-form-radio-group>
                  </b-form-group>
                </div>
              </div>
              <div class="clearfix">
                <div class="float-left text-right mr-3 width-100">
                  <span>公交</span>
                </div>
                <div class="float-left">
                  <b-form-group>
                    <b-form-radio-group :options="[
                      { text: '有', value: '有' },
                      { text: '无', value: '无' }]">
                    </b-form-radio-group>
                  </b-form-group>
                </div>
              </div>
            </div>
            <div class="card-header border-top">其他配套</div>
            <div class="card-body">
              <div class="clearfix">
                <div class="float-left text-right mr-3 width-100">
                  <span>供电情况</span>
                </div>
                <div class="float-left">
                  <b-form-group>
                    <b-form-radio-group :options="[
                      { text: '已接通', value: '已接通' },
                      { text: '未接通', value: '未接通' },
                      { text: '太阳能发电', value: '太阳能发电' },
                      { text: '柴油发电机', value: '柴油发电机' }]">
                    </b-form-radio-group>
                  </b-form-group>
                </div>
              </div>
              <div class="clearfix">
                <div class="float-left text-right mr-3 width-100">
                  <span>供水设施</span>
                </div>
                <div class="float-left">
                  <b-form-group>
                     <b-form-radio-group :options="[
                      { text: '已接通', value: '已接通' },
                      { text: '未接通', value: '未接通' },
                      { text: '井水', value: '井水' },
                      { text: '自来水', value: '自来水' },
                      { text: '山泉水', value: '山泉水' }]">
                    </b-form-radio-group>
                  </b-form-group>
                </div>
              </div>
              <div class="clearfix">
                <div class="float-left text-right mr-3 width-100">
                  <span>灌溉设施</span>
                </div>
                <div class="float-left">
                  <b-form-group>
                     <b-form-checkbox-group :options="[
                      { text: '畦灌', value: '畦灌' },
                      { text: '沟灌', value: '沟灌' },
                      { text: '淹灌', value: '淹灌' },
                      { text: '漫灌', value: '漫灌' },
                      { text: '普通喷灌', value: '普通喷灌' },
                      { text: '微喷灌', value: '微喷灌' },
                      { text: '滴灌', value: '滴灌' },
                      { text: '渗灌', value: '渗灌' }]">
                    </b-form-checkbox-group>
                  </b-form-group>
                </div>
              </div>
              <div class="clearfix">
                <div class="float-left text-right mr-3 width-100">
                  <span>附属设施</span>
                </div>
                <div class="float-left">
                  <b-form-group>
                     <b-form-checkbox-group :options="[
                      { text: '温室大棚', value: '温室大棚' },
                      { text: '畜禽舍', value: '畜禽舍' },
                      { text: '农机房', value: '农机房' },
                      { text: '晾晒场', value: '晾晒场' },
                      { text: '生活用房', value: '生活用房' },
                      { text: '水井', value: '水井' }]">
                    </b-form-checkbox-group>
                  </b-form-group>
                </div>
              </div>
              <div class="clearfix">
                <div class="float-left text-right mr-3 width-100">
                  <span>场地平整</span>
                </div>
                <div class="float-left">
                  <b-form-group>
                     <b-form-radio-group :options="[
                      { text: '起伏小', value: '起伏小' },
                      { text: '平坦', value: '平坦' },
                      { text: '起伏大', value: '起伏大' }]">
                    </b-form-radio-group>
                  </b-form-group>
                </div>
              </div>
              <div class="clearfix">
                <div class="float-left text-right mr-3 width-100">
                  <span>相关配套</span>
                </div>
                <div class="float-left">
                  <b-form-group>
                     <b-form-checkbox-group :options="[
                      { text: '农科站', value: '农科站' },
                      { text: '农副产品', value: '农副产品' },
                      { text: '批发市场', value: '批发市场' },
                      { text: '冷藏保鲜', value: '冷藏保鲜' },
                      { text: '仓储物流', value: '仓储物流' }]">
                    </b-form-checkbox-group>
                  </b-form-group>
                </div>
              </div>
              <div class="clearfix">
                <div class="float-left text-right mr-3 width-100">
                  <span>附近产业集群</span>
                </div>
                <div class="float-left">
                  <b-form-group>
                     <b-form-radio-group :options="[
                      { text: '种植业区', value: '种植业区' },
                      { text: '养殖业区', value: '养殖业区' },
                      { text: '生态休闲农业区', value: '生态休闲农业区' },
                      { text: '其它集群', value: '其它集群' }]">
                    </b-form-radio-group>
                  </b-form-group>
                </div>
              </div>
            </div>
            <div class="card-header border-top">经营信息</div>
            <div class="card-body">
              <div class="clearfix">
                <div class="float-left text-right mr-3 width-100">
                  <span>适合何种经营</span>
                </div>
                <div class="float-left">
                  <b-form-group>
                    <b-form-radio-group :options="[
                      { text: '种植作物', value: '种植作物' },
                      { text: '种植树木', value: '种植树木' },
                      { text: '养殖休闲', value: '养殖休闲' },
                      { text: '农业', value: '农业' },]">
                    </b-form-radio-group>
                  </b-form-group>
                </div>
              </div>
              <div class="clearfix">
                <div class="float-left text-right mr-3 width-100">
                  <span>地块利用现状</span>
                </div>
                <div class="float-left">
                  <b-form-group>
                    <b-form-radio-group :options="[
                      { text: '有', value: '有' },
                      { text: '无', value: '无' }]">
                    </b-form-radio-group>
                  </b-form-group>
                </div>
              </div>
              <div class="clearfix">
                <div class="float-left text-right mr-3 width-100">
                  <span>劳动力资源</span>
                </div>
                <div class="float-left">
                  <b-form-group>
                    <b-form-radio-group :options="[
                      { text: '充足', value: '充足' },
                      { text: '一般', value: '一般' },
                      { text: '匮乏', value: '匮乏' }]">
                    </b-form-radio-group>
                  </b-form-group>
                </div>
              </div>
              <div class="clearfix">
                <div class="float-left text-right mr-3 width-100">
                  <span>主要收入来源</span>
                </div>
                <div class="float-left">
                  <b-form-group>
                    <b-form-radio-group :options="[
                      { text: '种植养殖', value: '种植养殖' },
                      { text: '外出务工', value: '外出务工' }]">
                    </b-form-radio-group>
                  </b-form-group>
                </div>
              </div>
              <div class="clearfix">
                <div class="float-left text-right mr-3 width-100">
                  <span>税收优惠</span>
                </div>
                <div class="float-left">
                  <b-form-group>
                    <b-form-radio-group :options="[
                      { text: '有', value: '有' },
                      { text: '无', value: '无' }]">
                    </b-form-radio-group>
                  </b-form-group>
                </div>
              </div>
              <div class="clearfix">
                <div class="float-left text-right mr-3 width-100">
                  <span>最低收购政策</span>
                </div>
                <div class="float-left">
                  <b-form-group>
                    <b-form-radio-group :options="[
                      { text: '有', value: '有' },
                      { text: '无', value: '无' }]">
                    </b-form-radio-group>
                  </b-form-group>
                </div>
              </div>
              <div class="clearfix">
                <div class="float-left text-right mr-3 width-100">
                  <span>农业补贴</span>
                </div>
                <div class="float-left">
                  <b-form-group>
                    <b-form-checkbox-group  :options="[
                      { text: '地力补贴', value: '地力补贴' },
                      { text: '种粮直补', value: '种粮直补' },
                      { text: '良种补贴', value: '良种补贴' },
                      { text: '农资补贴', value: '农资补贴' },
                      { text: '农机购置补贴', value: '农机购置补贴' },
                      { text: '农机报废补贴', value: '农机报废补贴' },
                      { text: '土壤有机质提升补贴', value: '土壤有机质提升补贴' }]">
                    </b-form-checkbox-group>
                  </b-form-group>
                </div>
              </div>
              <div class="clearfix">
                <div class="float-left text-right mr-3 width-100">
                  <span>牧业补贴</span>
                </div>
                <div class="float-left">
                  <b-form-group>
                    <b-form-checkbox-group :options="[
                      { text: '畜牧良种补贴', value: '畜牧良种补贴' },
                      { text: '动物防疫补贴', value: '动物防疫补贴' }]">
                    </b-form-checkbox-group>
                  </b-form-group>
                </div>
              </div>
              <div class="clearfix">
                <div class="float-left text-right mr-3 width-100">
                  <span>贷款补贴</span>
                </div>
                <div class="float-left">
                  <b-form-group>
                    <b-form-checkbox-group :options="[
                      { text: '贷款贴息', value: '贷款贴息' },
                      { text: '贷款利息优惠', value: '贷款利息优惠' }]">
                    </b-form-checkbox-group>
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>
          <div class="container p-0">
            <div class="row pb-3 m-0">
              <div
                class="
                  col-12
                  p-0
                  d-flex
                  flex-column
                  py-3
                  align-items-center
                  justify-content-center
                "
              >
                 <b-button
                    variant="success mt-3 width-160"
                    >立即评估</b-button
                  >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <map-footer></map-footer>
  </div>
</template>

<script>
import city from "@/components/city/city.vue";
import datePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/zh-cn";
import {mapGetters} from "vuex";
export default {
  components: {
    city,
    datePicker,
  },
  data() {
    return {
      isAreaShow: false,
      metreArray: ['商业及住宅','公共用地', '农房'],
      areaValue: "",
      classId: "",
      acreage: '亩',
      para:{}
    };
  },
  computed: {
     ...mapGetters(["area", "dict"]),
     getChildUseLandList() {
				let c = this.dict.landUseType.find(x=> x.id == this.classId);
				if (c)  {
					return c.children;
				} 
				return []
			},
  },
  methods: {
    onChooseArea(a) {
      this.areaValue =
        a.province.name +
        (a.city.name == "市辖区" ? "" : " " + a.city.name) +
        " " +
        a.area.name;
      this.para.area = a;
      this.isAreaShow = false;
    },
    maChange() { 
      
    },
    onClassClick(m) {
      this.classId = m.id;
      if(this.metreArray.includes(m.name)) {
        this.acreage = '平方米';
      } else {
        this.acreage = '亩';
      }
    },
    onUlClick(m) {
      this.landUse = m.id;
    },
    onToolsChange(t) {
      this.para.classId = t.id;
    },
    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
  },
  created() {
    if (this.$route.query.name) {
      let m = this.tools.find((x) => x.text == this.$route.query.name);
      if (m) {
        this.toolsId = m.id;
        console.log(this.toolsId);
      }
    }
  },
};
</script>

<style scoped>
.screen .active {
		background: #00cc00;
		color: #FFFFFF;
		border-radius: 6px;
	}

</style>
